@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500;600;700&display=swap");
@import "./_variables.scss";
@import "../components/Toastify/Toastify.scss";

body {
  font-family: "Fira Sans";
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.fas {
  font-family: "FontAwesome";
}

.color-green {
  color: var(--color-success);
}

.color-red {
  color: var(--color-danger);
}

*,
*:hover,
*:active,
*:focus {
  scroll-behavior: smooth !important;
  outline: none !important;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1.5s;
  animation-duration: 0.8s;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

.text-danger {
  color: var(--color-danger);
}

.text-success {
  color: var(--color-success);
}

.bold {
  font-weight: 700;
}

.aks-btn {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  border: 1px solid var(--color-primary);
  padding: 10px 35px;
  border-radius: 3px;
  font-size: 12px;
  text-transform: uppercase;
  background-color: var(--color-primary);
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-weight: 600;

  &:before {
    border-radius: 4px;
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--color-primary-hover);
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    opacity: 0;
  }
  &:hover,
  &:focus,
  &:active {
    color: white;
    border-color: var(--color-primary-hover);
    text-decoration: none;

    &:before,
    &:before,
    &:before {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      opacity: 1;
    }
  }

  .fas {
    font-family: "FontAwesome";
    margin-left: 4px;
  }

  &.aks-btn--icon {
    padding: 5px 8px !important;
    .fa,
    .fas {
      margin-left: 0;
      font-size: 14px;
    }
  }

  &:disabled {
    background-color: var(--color-light);
    border-color: var(--color-light);
    color: #aaa;

    &:before {
      background-color: transparent;
    }
  }

  &.green-btn {
    background: var(--color-success);
    border-color: var(--color-success);

    &:before {
      background: var(--color-success-hover);
    }

    &:hover,
    &:focus,
    &:active {
      border-color: var(--color-success-hover);
    }
  }

  &.red-btn {
    background: var(--color-danger);
    border-color: var(--color-danger);

    &:before {
      background: var(--color-danger-hover);
    }

    &:hover,
    &:focus,
    &:active {
      border-color: var(--color-danger-hover);
    }
  }

  &.small-btn {
    padding: 5px 20px;
  }
}

.aks-table-container {
  max-width: 100%;
  overflow-x: scroll;
  box-shadow: rgba(0, 0, 0, 0.1) 0 5px 25px;

  .aks-table {
    background-color: white;
    border-radius: 4px;

    .aks-btn {
      padding: 5px 15px;
      font-size: 10px;
    }

    thead {
      background-color: var(--color-primary);

      .MuiTableCell-root {
        font-size: 12px;
        line-height: 16px;
        padding: 10px 12px;
        color: white;
      }
    }

    tbody {
      tr {
        transition: 0.4s;
        cursor: pointer;
        font-size: 10px;

        &:hover {
          background-color: var(--color-light);
        }

        .MuiTableCell-root {
          font-size: 12px;
          padding: 10px 12px;

          &:last-child {
            min-width: 90px;
          }
        }
      }
    }
  }

  .MuiTableCell-head {
    color: white;

    .MuiTableSortLabel-root {
      color: white;
      opacity: 0.6;
      transition: 0.4s;

      .MuiTableSortLabel-icon {
        font-size: 14px;
      }

      &:hover {
        opacity: 0.8;
      }

      &.MuiTableSortLabel-active {
        opacity: 1;

        .MuiTableSortLabel-icon {
          color: white !important;
        }
      }
    }
  }
}

.aks-pagination {
  margin-top: 20px;

  & > ul {
    justify-content: center;
  }
}

.aks-table-filter {
  margin: 10px auto;
  position: relative;

  input {
    border: none;
    border-bottom: 1px solid var(--color-primary);
    background-color: transparent;
    height: 41px;
    width: 80%;
    max-width: 300px;
    padding-right: 40px;
    transition: 0.4s;
    color: var(--color-primary);
    font-size: 14px;
    line-height: 40px;

    &::placeholder {
      font-weight: 400;
      color: var(--color-primary);
    }

    *:focus {
      border-color: var(--color-secondary);
    }
  }

  .aks-btn {
    padding: 0;
    line-height: 28px;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0;
    bottom: 5px;

    .fas {
      margin-left: 0;
    }
  }
}

.form-lnks {
  margin-top: 30px;

  .form-lnk {
    display: block;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: var(--color-primary);
    margin: 10px auto;

    a {
      color: var(--color-primary);
      transition: 0.4s;
      display: inline-block;
      margin-left: 4px;

      &:hover {
        color: var(--color-dark-hover);
      }
    }

    &:first-child {
      margin-top: 30px !important;
    }
  }
}

@media (max-width: 767px) {
  .aks-table-filter {
    input {
      width: 100%;
      max-width: 100%;
    }
  }
}

.checkbox-input {
  display: flex;
  align-items: center;

  label {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 10px;
    font-weight: 700;
    color: var(--color-primary);
  }
}

.status-badge {
  padding: 4px 11px;
  border-radius: 60px;
  font-size: 10px;

  &.OnHold {
    background-color: var(--color-warning);
  }

  &.Processing {
    background-color: var(--color-primary);
    color: white;
  }

  &.Canceled {
    background-color: var(--color-danger);
    color: white;
  }

  &.Completed {
    background-color: var(--color-success);
    color: white;
  }
}

.as-link {
  color: #000;
  opacity: 0.4;
  font-size: 12px;
  text-decoration: underline;
  display: inline-block;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 1;
  }
}
