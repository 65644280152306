/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
  width: 100%;
  max-width: 500px;
}

/** Used to define the position of the ToastContainer **/
.Toastify__toast-container--top-left {
}
.Toastify__toast-container--top-center {
}
.Toastify__toast-container--top-right {
}
.Toastify__toast-container--bottom-left {
}
.Toastify__toast-container--bottom-center {
}
.Toastify__toast-container--bottom-right {
}

/** Classes for the displayed toast **/
.Toastify__toast {
  font-weight: 700;
}
.Toastify__toast--rtl {
}
.Toastify__toast--dark {
}
.Toastify__toast--default {
}
.Toastify__toast--info {
}
.Toastify__toast--success {
  background: var(--color-success);
  color: #fff;
}
.Toastify__toast--warning {
  background: var(--color-warning);
  color: var(--color-dark);
}
.Toastify__toast--error {
  background: var(--color-danger);
  color: #fff;
}
.Toastify__toast-body {
  padding: 3px 10px;
  font-family: "Fira Sans", sans-serif;
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
}
.Toastify__close-button--default {
}
.Toastify__close-button > svg {
}
.Toastify__close-button:hover,
.Toastify__close-button:focus {
}

/** Classes for the progress bar **/
.Toastify__progress-bar {
}
.Toastify__progress-bar--animated {
}
.Toastify__progress-bar--controlled {
}
.Toastify__progress-bar--rtl {
}
.Toastify__progress-bar--default {
}
.Toastify__progress-bar--dark {
}
