.only-mob {
  display: none;
}

menu {
  margin: 0;
  padding: 10px 30px;
  background-color: var(--color-dark);
  border-bottom: 10px solid var(--color-primary);
  box-shadow: rgba(51, 51, 51, 0.1) 0 10px 20px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  z-index: 999;

  .logo {
    max-height: 50px;
  }

  .login-lnk {
    display: inline-block;
    font-size: 0.8em;
    text-transform: uppercase;
    font-weight: 500;
    text-decoration: none;
    color: #fff;
    opacity: 0.4;
    transition: 0.4s;

    &.active,
    &:hover {
      opacity: 1;
    }
  }

  .menu-right-part {
    text-align: right;

    .menu-wrapper {
      .navigation-list {
        margin-bottom: 0;
        list-style: none;
        padding-left: 0;
        display: inline-block;

        li {
          display: inline-block;

          a {
            display: inline-block;
            font-size: 0.8em;
            text-transform: uppercase;
            font-weight: 500;
            text-decoration: none;
            color: #fff;
            opacity: 0.4;
            transition: 0.4s;

            &.active {
              opacity: 1;
            }
          }

          &.aks-dropdown {
            position: relative;

            .dropdown-title {
              display: inline-block;
              font-size: 0.8em;
              text-transform: uppercase;
              font-weight: 500;
              text-decoration: none;
              color: #fff;
              opacity: 0.4;
              transition: 0.4s;
              cursor: pointer;

              i {
                font-style: normal;
                margin-left: 5px;
                font-family: "FontAwesome";
              }
            }

            .dropdown-items {
              padding-left: 0;
              position: absolute;
              height: 0;
              overflow: hidden;
              z-index: -1;
              opacity: 0;
              transition: 0.4s ease-in-out;
              top: 40px;
              margin-top: -15px;
              width: 120%;
              min-width: 120px;
              right: 0;

              left: -10px;
              margin-bottom: 0;
              text-align: center;

              li {
                display: block;
                background-color: var(--color-secondary);

                a {
                  display: block;
                  padding: 7px 0;
                  border-bottom: 1px solid var(--color-primary);

                  &:hover {
                    background-color: var(--color-primary);
                  }
                }
              }
            }

            &:hover {
              .dropdown-items {
                height: 100px;
                z-index: 999;
                opacity: 1;
              }
            }
          }
        }

        &.navigation-links {
          float: left;
          li {
            a {
              padding: 5px 10px;

              &:hover {
                opacity: 1;
              }
            }
          }
        }

        &.navigation-icons {
          margin-left: 40px;

          li {
            a {
              padding: 5px 20px;

              span {
                margin-left: 3px;
              }

              &:hover {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .only-mob {
    display: block;
  }

  menu {
    padding: 10px 0;

    .menu-right-part {
      .fa-bars {
        color: #fff;
      }

      .menu-wrapper {
        transition: 0.8s ease-in-out;
        opacity: 0;
        position: fixed;
        overflow-y: scroll;
        top: 0;
        left: 100vw;
        right: 0vw;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        background-color: var(--color-dark);
        z-index: 999;
        display: block;
        text-align: center;

        &.mob-menu-opened {
          left: 0;
          right: 0;
          opacity: 1;
        }

        .mob-logo {
          display: block;
          max-height: 100px;
          margin: 2vh auto 10vh;
          padding: 0 15px;
        }

        .navigation-list {
          display: block;
          text-align: center;

          li {
            display: block;

            a {
              display: block;
            }

            &.aks-dropdown {
              position: relative;

              .dropdown-title {
                display: none;
              }

              .dropdown-items {
                padding-left: 0;
                position: static;
                height: auto;
                opacity: 1;
                z-index: 999;
                transition: 0.4s ease-in-out;
                margin-top: 0;
                width: 100%;
                left: 0;
                margin-bottom: 0;
                text-align: center;

                li {
                  display: block;
                  background-color: initial;

                  a {
                    display: block;
                    padding: 15px;
                    border-bottom: 1px solid var(--color-primary);

                    &:hover {
                      background-color: var(--color-primary);
                    }
                  }
                }
              }
            }
          }

          &.navigation-links {
            float: none;

            li {
              a {
                padding: 15px;
                border-bottom: 1px solid var(--color-primary);

                &:hover {
                  background-color: var(--color-primary);
                }
              }
            }
          }

          &.navigation-icons {
            margin-left: 0;

            li {
              a {
                padding: 15px;
                border-bottom: 1px solid var(--color-primary);

                &:hover {
                  background-color: var(--color-primary);
                }
              }
            }
          }
        }

        .menu-close {
          display: inline-block;
          text-align: center;
          color: #fff;
          opacity: 0.4;
          font-size: 0.7em;
          text-transform: uppercase;
          font-weight: 600;
          transition: 0.4s;
          margin: 10vh auto 7vh;
          cursor: pointer;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}
