:root {
  --color-primary: #34495e;
  --color-primary-hover: #273544;
  --color-secondary: #283747;
  --color-dark: #17202a;
  --color-dark-hover: #06080a;
  --color-light: #f0f3f4;
  --color-light-hover: #c9caca;
  --color-success: #28b463;
  --color-success-hover: #1e8a4b;
  --color-danger: #e74c3c;
  --color-danger-hover: #a1362b;
  --color-warning: #fcd52a;
  --size-title: 32px;
  --size-subtitle: 18px;
  --size-content: 12px;
}
