#pageLoader {
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #fff;
  z-index: 99999;

  span {
    width: 100px;
    height: 100px;

    border: 3px solid var(--color-primary);
    border-top: 5px solid var(--color-dark);
    border-radius: 100%;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    animation: spin 1.2s infinite linear;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg) scale(0.95);
  }

  50% {
    transform: rotate(180deg) scale(1);
  }

  100% {
    transform: rotate(360deg) scale(0.95);
  }
}
